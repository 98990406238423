import { Injectable, Output } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../../admin/users/models/user.model';

@Injectable()
export class UserService {
  constructor(public jwtHelper: JwtHelperService) {}

  @Output()
  public group;

  public groupChanges: ((group) => void)[] = [];

  @Output()
  public user: any;

  public setUser(user: any) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  public setToken(token: any) {
    localStorage.setItem('token', token);
  }

  public clear() {
    localStorage.removeItem('token');
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    const isExpired = this.jwtHelper.isTokenExpired(token);
    return !isExpired;
  }

  public getUser(): User {
    const str = localStorage.getItem('user');
    if (str) {
      return JSON.parse(str);
    }
  }

  public setGroup(group) {
    this.group = group;
    this.groupChanges.forEach((func) => func(group));
  }

  public onGroupChange(callback) {
    this.groupChanges.push(callback);
  }

  public getGroup() {
    return this.group;
  }
}
