import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PagedResult } from '@base/lobby-base.component';
import { CacheKeys, UsersCache } from '@services/users-cache/users-cache.service';
import { Version, VersionsService } from '@services/versions/versions.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-item-versions',
  templateUrl: './item-versions.component.html',
  styleUrls: ['./item-versions.component.css'],
})
export class ItemVersionsComponent implements OnInit {
  constructor(private versionsService: VersionsService, private usersCache: UsersCache, public router: Router, public route: ActivatedRoute) {}

  @Output() versionChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input() recordId: string;

  @Input() modelName: string;

  get activeRevision() {
    return this.revision;
  }

  @Input() set activeRevision(value) {
    this.revision = value;
    firstValueFrom(this.versionsService.getByIdAndModelName(this.modelName, this.recordId)).then((pagedResult: PagedResult<Version>) => {
      this.versions = pagedResult.rows;
    });
  }

  revision: string;

  users = {};

  versions: Version[] = [];

  async ngOnInit(): Promise<void> {
    this.users = await this.usersCache.getCollection(CacheKeys.users);
  }

  async activateVersion(versionId) {
    await firstValueFrom(this.versionsService.activateVersion(this.modelName, this.recordId, versionId));
    this.versionChanged.emit(versionId);
  }

  async deleteAllVersions(event) {
    event.stopPropagation();
    if (confirm('Version will be deleted.')) {
      await firstValueFrom(this.versionsService.deleteVersions(this.modelName, this.recordId));
      firstValueFrom(this.versionsService.getByIdAndModelName(this.modelName, this.recordId)).then((pagedResult: PagedResult<Version>) => {
        this.versions = pagedResult.rows;
        this.activeRevision = null;
      });
    }
  }

  async deleteVersion(versionId, event) {
    event.stopPropagation();
    if (confirm('Version will be deleted.')) {
      await firstValueFrom(this.versionsService.deleteVersion(this.modelName, this.recordId, versionId));
      this.activeRevision = this.revision;
    }
  }
}
