import { Injectable } from '@angular/core';
import { BaseService } from '@base/base.service';
import { Factor } from '../models/factor.model';

@Injectable()
export class FactorsService extends BaseService<Factor> {
  getAll() {
    throw new Error('Method not implemented.');
  }

  serviceUrl = '/api/factors';
}
