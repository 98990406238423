import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class UploadService {
  constructor(private http: HttpClient) {}

  serviceUrl = '/api/upload';

  cacheSubscription: Subscription;

  upload(item: any): Observable<any> {
    const formData = new FormData();
    formData.append('file', item);

    return this.http.post(this.serviceUrl, formData);
  }
}
