import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OperatorPipe } from '@questions/question-state-rule/operator.pipe';
import { DatePipe } from './date/date.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { SortByPipe } from './sort-by/sort-by.pipe';
import { AdaptHeightDirective } from '../directives/adapt-height';
import { AdaptWidthDirective } from '../directives/adapt-width';
import { DetectChangesDirective } from '../directives/change.directive';
import { ResizableDirective } from '../directives/resizable.directive';
import { ClickStopPropagationDirective } from '../directives/stop-prop';

const declarations = [
  OperatorPipe,
  SortByPipe,
  FilterPipe,
  DatePipe,
  ResizableDirective,
  AdaptWidthDirective,
  ClickStopPropagationDirective,
  DetectChangesDirective,
  AdaptHeightDirective,
];
@NgModule({
  imports: [CommonModule],
  declarations,
  exports: [...declarations],
})
export class PipesModule {}
