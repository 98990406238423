import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[resizable]',
})
export class ResizableDirective {
  // tslint:disable-next-line:no-input-rename
  @Input('resizable') resizable: boolean;

  constructor(private el: ElementRef) {}

  viewport() {
    let e: any = window;
    let a = 'inner';
    if (!('innerWidth' in window)) {
      a = 'client';
      e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
  }
}
