import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Injectable()
export class BaseService<T = any> {
  constructor(protected http: HttpClient) {}

  serviceUrl = '/api/';

  items: T[];

  cacheSubscription: Subscription;

  create(item: T): Observable<any> {
    item = this.cleanMeta(item);

    return this.http.post<T>(this.serviceUrl, item);
  }

  update(item: Partial<T>): Observable<any> {
    item = this.cleanMeta(item);
    return this.http.put<Partial<T>>(this.serviceUrl, item);
  }

  publish(id: string): Observable<any> {
    return this.http.patch<Partial<T>>(`${this.serviceUrl}/${id}`, {});
  }

  query(options = {}): Observable<any> {
    return this.http.post<T[]>(`${this.serviceUrl}/search/by-crit`, options);
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any[]>(`${this.serviceUrl}/${id}`);
  }

  get(id: string): Observable<any> {
    return this.http.get<any[]>(`${this.serviceUrl}/${id}`);
  }

  cleanMeta(original) {
    return this.cleanRecurse(JSON.parse(JSON.stringify(original)));
  }

  cleanRecurse(clone) {
    if (Array.isArray(clone)) {
      clone = clone.map((item) => {
        return this.cleanRecurse(item);
      });
    } else if (typeof clone === 'object') {
      Object.keys(clone).forEach((key) => {
        if (key === '_data' || key.startsWith('_meta_') || key === 'template') {
          delete clone[key];
        }
        if (clone[key] && typeof clone[key] === 'object') {
          clone[key] = this.cleanRecurse(clone[key]);
        }
      });
    }
    return clone;
  }
}
