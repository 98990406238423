import { Component, Output, EventEmitter, NgZone, OnInit } from '@angular/core';
import { UserService } from '@services/user.context/user.context.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  /**
   *
   */
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,

    private jwtHelper: JwtHelperService,
    private _ngZone: NgZone
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.user.subscribe(async ($event) => {
      this._ngZone.run(async () => {});
    });
  }

  ngOnInit(): void {
    (window as any).userLoggedIn = (gResponse) => {
      const user = this.jwtHelper.decodeToken(gResponse.credential);
      this.authService.token({ email: user.email, idToken: gResponse.credential, authorizationCode: user.email_verified }).subscribe((result) => {
        if (result.token) {
          this.userService.setToken(result.token);
          this.userService.setUser(result.user);
          this.router.navigateByUrl('/dashboard').catch((error) => console.error(error));
        }
      });
    };
  }

  @Output()
  public userData: any;

  @Output()
  public user = new EventEmitter();

  rememberMe: boolean;

  userName: string;

  password: string;

  public async;

  scale(element, clsname) {
    element.className = `scaler-${clsname}`;
  }

  async tryLogin() {
    this.authService.token({ email: this.userName, password: this.password }).subscribe((result) => {
      if (result.token) {
        this.userService.setToken(result.token);
        this.userService.setUser(result.user);
        this.router.navigate(['dashboard']);
      }
    });
  }
}
