import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { UserService } from '@services/user.context/user.context.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';
import { TranslateModule } from '@ngx-translate/core';

export const userRoutes: Routes = [
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: 'signin', component: LoginComponent },
];

@NgModule({
  imports: [CommonModule, TranslateModule.forChild(), FormsModule],
  declarations: [LoginComponent],
  providers: [UserService, JwtHelperService, AuthService],
  exports: [],
})
export class UserModule {}
