import { NgModule, ModuleWithProviders } from '@angular/core';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { HotkeyModule, HotkeysService } from 'angular2-hotkeys';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularSplitModule } from 'angular-split';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TagInputModule } from 'ngx-chips';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { AgGridModule } from 'ag-grid-angular';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { MomentModule } from 'ngx-moment';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CKControlComponent } from './ckcontrol/ckcontrol.component';

@NgModule({
  imports: [
    AccordionModule,
    CommonModule,
    AgGridModule,
    FormsModule,
    ColorPickerModule,
    ReactiveFormsModule,
    NzToolTipModule,
    ModalModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forChild(),
    HotkeyModule.forRoot(),
    NgxDatatableModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    AngularSplitModule,
    TagInputModule,
    BsDropdownModule,
    CKEditorModule,
    MomentModule,

    UiSwitchModule.forRoot({
      size: 'small',
      color: 'rgb(0, 189, 99)',
      switchColor: '#80FFA2',
      defaultBgColor: '#00ACFF',
      defaultBoColor: '#476EFF',
      checkedLabel: 'on',
      uncheckedLabel: 'off',
    }),
  ],
  declarations: [CKControlComponent],
  providers: [TranslateService, HotkeysService, BsModalRef, BsDropdownConfig],
  exports: [
    AccordionModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MomentModule,
    AngularSplitModule,
    TagInputModule,
    ColorPickerModule,
    HotkeyModule,
    ModalModule,
    TranslatePipe,
    BsDropdownModule,
    AgGridModule,
    CKEditorModule,
    NzToolTipModule,
    CKControlComponent,
    NgxDatatableModule,
    BsDatepickerModule,
    TabsModule,
  ],
})
export class SharedModulesModule {
  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
  }

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModulesModule,
      providers: [],
    };
  }
}
