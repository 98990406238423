import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-lobby-filter',
  templateUrl: './lobby-filter.component.html',
  styleUrls: ['./lobby-filter.component.css'],
})
export class LobbyFilterComponent {
  @Output('filter') filter: EventEmitter<string> = new EventEmitter<string>();

  @Output('reset') reset: EventEmitter<string> = new EventEmitter<string>();

  @Input('options') options: any[];

  @Input('activeFilter') activeFilter: any;

  applyFilter(value) {
    this.filter.emit(value);
  }

  isFiltered() {
    return this.activeFilter;
  }

  resetFilter() {
    this.reset.emit();
  }
}
