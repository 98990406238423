import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class FilterService {
  filter = new EventEmitter<any>();

  _on = false;

  name: string;

  toggleFilter() {
    this._on = !this._on;
    this.filter.emit(this._on);
  }

  showFilter() {
    this.filter.emit(true);
  }

  hideFilter() {
    this.filter.emit(false);
  }
}
