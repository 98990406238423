import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { FactorsService } from './factors/factors.service';
import { ProService } from './pro-list/pro.service';
import { QuestionGroupsService } from './question-group/question-groups.service';
import { QuestionsService } from './question-list/questions.service';
import { QuestionStateRuleService } from './question-state-rule/question-state-rule.service';
import { QuestionTypesService } from './question-type/question-types.service';

export const enum CacheKeys {
  questionnaire = 'questionnaire',
  questions = 'questions',
  questionsGroups = 'questionsGroups',
  questionsTypes = 'questionsTypes',
  factors = 'factors',
  questionsStateRule = 'questionsStateRule',
}

@Injectable({
  providedIn: 'root',
})
export class QuestionsCache {
  constructor(
    private questionsService: QuestionsService,
    private questionTypesService: QuestionTypesService,
    private questionGroupsService: QuestionGroupsService,
    private questionStateRuleService: QuestionStateRuleService,
    private proService: ProService,
    private factorsService: FactorsService
  ) {}

  init() {
    this.isBusy = true;
    Promise.all([
      this.updateCache(CacheKeys.questionsGroups),
      this.updateCache(CacheKeys.questionsTypes),
      this.updateCache(CacheKeys.questionnaire),
      this.updateCache(CacheKeys.factors),
      this.updateCache(CacheKeys.questions),
      this.updateCache(CacheKeys.questionsStateRule),
    ]).then(() => {
      this.isBusy = false;
      this.onReady.emit();
    });
  }

  isBusy = false;

  public onReady: EventEmitter<void> = new EventEmitter<void>();

  public onUpdate: EventEmitter<CacheKeys> = new EventEmitter<CacheKeys>();

  async getCollection(cacheKey: CacheKeys) {
    await this.verifyCollection(cacheKey);
    return this.dictionaries[cacheKey];
  }

  async getArray(cacheKey: CacheKeys) {
    await this.verifyCollection(cacheKey);
    return JSON.parse(JSON.stringify(this._collections[cacheKey]));
  }

  async fromCollection(cacheKey: CacheKeys, id: string) {
    await this.verifyCollection(cacheKey);
    if (!this.dictionaries[cacheKey][id]) {
      console.error('object not found', cacheKey, id);
    }
    return this.dictionaries[cacheKey][id];
  }

  async verifyCollection(cacheKey: CacheKeys) {
    if (!this.dictionaries[cacheKey] || !this._collections[cacheKey]) {
      await this.updateCache(cacheKey);
    }
  }

  dictionaries = {
    [CacheKeys.questionnaire]: null,
    [CacheKeys.questions]: null,
    [CacheKeys.questionsGroups]: null,
    [CacheKeys.questionsTypes]: null,
    [CacheKeys.factors]: null,
    [CacheKeys.questionsStateRule]: null,
  };

  _collections = {
    [CacheKeys.questionnaire]: null,
    [CacheKeys.questions]: null,
    [CacheKeys.questionsGroups]: null,
    [CacheKeys.questionsTypes]: null,
    [CacheKeys.factors]: null,
    [CacheKeys.questionsStateRule]: null,
  };

  cacheServices = {
    [CacheKeys.questionnaire]: this.proService,
    [CacheKeys.questions]: this.questionsService,
    [CacheKeys.questionsGroups]: this.questionGroupsService,
    [CacheKeys.questionsTypes]: this.questionTypesService,
    [CacheKeys.factors]: this.factorsService,
    [CacheKeys.questionsStateRule]: this.questionStateRuleService,
  };

  async updateCache(cacheKey: CacheKeys): Promise<void> {
    this.dictionaries[cacheKey] = {};
    const data = (await firstValueFrom(this.cacheServices[cacheKey].query())).rows;
    this.dictionaries[cacheKey] = this.toDictionary(data);
    this._collections[cacheKey] = data;
    this.onUpdate.emit(cacheKey);
  }

  removeSingleEntry(cacheKey: CacheKeys, id) {
    delete this.dictionaries[cacheKey][id];
    const organ = this._collections[cacheKey].filter((single) => single._id === id);
    const indexPosition = this._collections[cacheKey].indexOf(organ[0]);
    if (indexPosition > -1) {
      this._collections[cacheKey].splice(indexPosition, 1);
    }
    this.onUpdate.emit();
  }

  updateSingleEntry(cacheKey: CacheKeys, id, item) {
    const newRef = JSON.parse(JSON.stringify(item));
    this.dictionaries[cacheKey] = this.dictionaries[cacheKey] || {};
    this.dictionaries[cacheKey][id] = newRef;

    this._collections[cacheKey] = this._collections[cacheKey] || [];

    const organ = this._collections[cacheKey].filter((single) => single._id === id);
    const indexPosition = this._collections[cacheKey].indexOf(organ[0]);
    if (indexPosition > -1) {
      this._collections[cacheKey][indexPosition] = newRef;
    } else {
      this._collections[cacheKey].push(newRef);
    }
    this.sendMessage(newRef);
    this.onUpdate.emit();
  }

  toDictionary(jArray: any[]) {
    const jObject: Record<string, any> = {};
    jArray.forEach((question) => {
      jObject[question._id] = question;
    });
    return jObject;
  }

  sendMessage(msg: string) {}
}
