import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-ckcontrol',
  templateUrl: './ckcontrol.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CKControlComponent,
    },
  ],
})
export class CKControlComponent implements ControlValueAccessor {
  public Editor: any = ClassicEditor;

  text: string;

  disabled: boolean;

  touched: boolean;

  textMode = false;

  onTouched: () => void;

  onChange: (data) => void;

  ckOptions = {
    embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
    removePlugins: 'elementspath',
    resize_enabled: false,
    toolbar: [
      {
        name: 'basicstyles',
        items: [
          'Removeformat',
          'Maximize',
          'Source',
          'Underline',
          'Bold',
          'Italic',
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          '-',
          'Blockquote',
          'CreateDiv',
          '-',
          'Styles',
          'JustifyLeft',
          'JustifyCenter',
          'JustifyRight',
          'JustifyBlock',
          '-',
          'BidiLtr',
          'BidiRtl',
          'Link',
          'Unlink',
          'Anchor',
          'Table',
          'HorizontalRule',
          'Iframe',
          'MediaEmbed',
          'Oembed',
          'Image',
          'Emoji',
        ],
      },
    ],
    uiColor: '#99000',
  };

  onLocalChange() {
    this.onChange(this.text);
  }

  writeValue(text: string): void {
    this.text = text;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
