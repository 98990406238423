import { Component, Input, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss'],
})
export class MediaPreviewComponent {
  @Input('url') url: string;

  @Input() width = '300px';

  @Input() height = '150px';

  constructor(public _ngZone: NgZone, public sanitizer: DomSanitizer) {}

  urlFrame: any;

  embedFrame: any;
}
