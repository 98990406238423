<div class="{{ class }} {{ className }}">
  <em class="{{ iconClass }} icon"></em>
  <h4>{{ title | translate }}</h4>
</div>

<button *ngIf="closeFn" class="close-button-{{ class }}" (click)="closeSlider()"><em class="icon-times-rectangle-o"></em></button>
<button *ngIf="enablePublish" class="publish-btn-{{ class }}" (click)="publishClick($event)" [ngClass]="{ 'can-publish': canPublish }">
  <em class="icon-upload-to-cloud1" title="Last published: {{ itemAge | amTimeAgo: true }}"></em>
</button>
<button *ngIf="enableSave" class="save-btn-{{ class }} {{ dirty }}" (click)="saveClick($event)">
  <em class="icon-floppy-o"></em>
</button>

<div class="toolbar">
  <ng-template [ngTemplateOutlet]="toolbar || defaultTemplate"></ng-template>
</div>

<ng-template #defaultTemplate> </ng-template>


