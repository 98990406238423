import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs-compat/Observable';

@Injectable()
export class AuthService {
  constructor(private http: HttpClient) {}

  serviceUrl = '/api/auth';

  signup(user: any): Observable<any> {
    return this.http.post<any[]>(this.serviceUrl, user);
  }

  signin(user: any): Observable<any> {
    return this.http.patch<any[]>(this.serviceUrl, user);
  }

  token(user: any): Observable<any> {
    return this.http.patch<any[]>(this.serviceUrl, user);
  }
}
