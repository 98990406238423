import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NameService {
  constructor(public translate: TranslateService) {

  }

  name: string;

  setName(name) {
    this.name = name;
  }

  getName() {
    return this.name;
  }
}
