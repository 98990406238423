import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { HeaderComponent } from './header/header.component';
import { PublicComponent } from './public.component';

import { FeaturesComponent } from './features/features.component';
import { FooterComponent } from './footer/footer.component';
import { MainComponent } from './main/main.component';
import { UserModule, userRoutes } from './user/user.module';
import { TranslateModule } from '@ngx-translate/core';

export const publicRoutes: Routes = [
  {
    path: '',
    component: PublicComponent,
    children: [...userRoutes],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule, UserModule, TranslateModule.forChild(), FormsModule],
  declarations: [PublicComponent, HeaderComponent, FeaturesComponent, FooterComponent, MainComponent],
  providers: [],
})
export class PublicModule {}
