import { Injectable, EventEmitter } from '@angular/core';
import { RemoteCacheService } from '@base/remote-cache.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DirtyService {
  SAVE_STATE = 'SAVED';

  constructor(private remoteCacheService: RemoteCacheService) {}

  _dirty: boolean;

  _saving: boolean;

  _error: boolean;

  detect: EventEmitter<any> = new EventEmitter<any>();

  error: EventEmitter<any> = new EventEmitter<any>();

  dirty: EventEmitter<boolean> = new EventEmitter<boolean>();

  saving: EventEmitter<boolean> = new EventEmitter<boolean>();

  onInit: EventEmitter<any> = new EventEmitter<any>();

  onChange: EventEmitter<any> = new EventEmitter<any>();

  onRefresh: EventEmitter<any> = new EventEmitter<any>();

  saveEmitter: EventEmitter<any> = new EventEmitter<any>();

  enableSaveEmitter: EventEmitter<any> = new EventEmitter<any>();

  save() {
    this.saveEmitter.emit();
  }

  enableSave(state) {
    this.enableSaveEmitter.emit(state);
  }

  refresh() {
    this.onRefresh.emit();
  }

  init() {
    this.onInit.emit(true);
  }

  change(map) {
    this.onChange.emit(map);
  }

  setDirty() {
    this._dirty = true;
    this.dirty.emit(true);
  }

  clearDirty() {
    this._dirty = false;
    this.dirty.emit(false);
    this.clearError();
  }

  setError() {
    this._error = true;
    this.error.emit(true);
  }

  clearError() {
    this._error = false;
    this.error.emit(false);
  }

  setSaving() {
    this._saving = false;
    this.saving.emit(true);
  }

  clearSaving() {
    this._saving = false;
    this.saving.emit(false);
  }

  detectChanges() {
    if (!this._dirty) {
      this.detect.emit();
    }
  }

  async clearRemoteCache(withRemote: boolean) {
    return firstValueFrom(this.remoteCacheService.clear(withRemote));
  }
}
