<div class="pad">
  <ul class="list-group">
    <li>
      <a class="no-wrap btn btn-danger" (click)="deleteAllVersions($event)"> <em class="icon-trash"></em> Delete all versions</a>
    </li>
    <li
      class="list-group-item list-group-item-action"
      [ngClass]="{ active: revision === version._id }"
      (click)="activateVersion(version._id)"
      *ngFor="let version of versions | sortBy : 'dates.entered' : 'desc'"
    >
      <div class="row">
        <div class="col-1 no-wrap">
          <input type="radio" [value]="version._id" name="versions" [checked]="revision === version._id" />
        </div>
        <div class="col-4 no-wrap" *ngIf="version.dates">
          {{ version.dates.entered | date : 'short' }}
        </div>
        <div class="col-5 no-wrap">
          <span *ngIf="users[version.owner.user]">
            {{ users[version.owner.user].email }}
          </span>
        </div>
        <div class="col-2" *ngIf="version">
          <a class="no-wrap btn btn-sm btn-danger" (click)="deleteVersion(version._id, $event)"> <em class="icon-trash"></em></a>
        </div>
      </div>
    </li>
  </ul>
</div>
