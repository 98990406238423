<button id="button-split" type="button" dropdownToggle class="btn btn-xs btn-outline dropdown-toggle dropdown-toggle-split">
  <span class="caret"></span>
</button>
<div id="dropdown-split" *dropdownMenu class="dropdown-menu dropdown-menu-left" role="menu">
  <form>
    <div class="form-group">
      <input type="text" class="form-control" placeholder="search" />
    </div>
  </form>

  <div *ngIf="isFiltered()">
    <a class="dropdown-item" (click)="resetFilter()"> Reset </a>
  </div>
  <div role="menuitem" *ngFor="let option of options">
    <a class="dropdown-item" (click)="applyFilter(option)">{{ option }} </a>
  </div>
</div>
