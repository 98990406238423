import { EventEmitter, Injectable } from '@angular/core';
import { Factor } from '../../models/factor.model';

@Injectable()
export class QuestionFactorsService {
  private _factors: Factor[] = [];

  getFactors(): Factor[] {
    return this._factors;
  }

  private _groups: string[];

  selected: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();

  setFromPro(data: string[]) {
    this._groups = JSON.parse(JSON.stringify(data));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addValueFromChoice(_groupData) {}

  getGroupData() {
    const returnMap = {};
    this._factors.forEach((element) => {
      returnMap[element.name] = true;
    });

    return Object.keys(returnMap);
  }
}
