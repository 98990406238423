import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort',
})
export class DatePipe implements PipeTransform {
  transform(value: any): string {
    return new Date(value).toDateString();
  }
}
