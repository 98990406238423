
<div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-light ">
  <div class="mx-auto">
    <h1 class="display-4 font-weight-normal">{{"PUBLIC.FEATURES.TITLE" | translate}}</h1>
    <p class="lead font-weight-normal">{{"PUBLIC.FEATURES.SUBHEADER" | translate}}</p>
    <a class="btn btn-outline-secondary" href="#">{{"PUBLIC.FEATURES.STARTFORFREE" | translate}}</a>
  </div>
  <div class="product-device shadow-sm d-none d-md-block"></div>
  <div class="product-device product-device-2 shadow-sm d-none d-md-block"></div>
</div>





<div class="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3 ">
  <div class="bg-dark mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden w-50">
    <div class="my-3 py-3">
      <h2 class="display-5">{{"PUBLIC.FEATURES.HEADER1" | translate}}</h2>
      <p class="lead">{{"PUBLIC.FEATURES.CONTENT1" | translate}}</p>
    </div>
    <div class="bg-light shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div>
  </div>
  <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden w-50">
    <div class="my-3 p-3">
      <h2 class="display-5">{{"PUBLIC.FEATURES.HEADER2" | translate}}</h2>
      <p class="lead">{{"PUBLIC.FEATURES.CONTENT2" | translate}}</p>
    </div>
    <div class="bg-dark shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div>
  </div>
</div>

<div class="d-md-flex flex-md-equal w-100 my-md-3 pl-md-3">
  <div class="bg-light mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden w-50">
    <div class="my-3 p-3">
        <h2 class="display-5">{{"PUBLIC.FEATURES.HEADER3" | translate}}</h2>
        <p class="lead">{{"PUBLIC.FEATURES.CONTENT3" | translate}}</p>
    </div>
    <div class="bg-dark shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div>
  </div>
  <div class="bg-primary mr-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center text-white overflow-hidden w-50">
    <div class="my-3 py-3">
        <h2 class="display-5">{{"PUBLIC.FEATURES.HEADER4" | translate}}</h2>
        <p class="lead">{{"PUBLIC.FEATURES.CONTENT4" | translate}}</p>
    </div>
    <div class="bg-light shadow-sm mx-auto" style="width: 80%; height: 300px; border-radius: 21px 21px 0 0;"></div>
  </div>
</div>

 