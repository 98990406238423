import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'operator',
})
export class OperatorPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'like':
        return 'containing';
      case 'nlike':
        return '!containing';
      case 'empty':
        return 'Empty';
      case 'hasValues':
        return 'Not Empty';
      case 'eq':
        return '=';
      case 'neq':
        return '!=';
      case 'lt':
        return '<';
      case 'gt':
        return '>';
      case 'lte':
        return '=<';
      case 'gte':
        return '=>';
      case 'bt':
        return '<=>';
      case 'bte':
        return '<==>';
      case 'ageRange':
        return 'ageRange';
    }
  }
}
