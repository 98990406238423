import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ResponsiveService {
  constructor(public translate: TranslateService) {

  }

  responsive: boolean;

  toggle(state) {
    this.responsive = state;
  }
}
