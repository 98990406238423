import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@services/user.context/user.context.service';

export function authGuardFn(auth: UserService, router: Router): boolean {
  if (!auth.isAuthenticated()) {
    router.navigate(['/signin']);
    return false;
  }
  return true;
}

@Injectable()
export class AuthGuardService {
  constructor(public auth: UserService, public router: Router) {}

  canActivate(): boolean {
    return authGuardFn(this.auth, this.router);
  }
}
