import { Injectable } from '@angular/core';
import { BaseService } from '@base/base.service';
import { Observable } from 'rxjs';
import { Treatment } from './models/treatments.model';

@Injectable()
export class TreatmentsService extends BaseService<Treatment> {
  serviceUrl = '/api/treatments';

  decrypt(item: any): Observable<any> {
    item = this.cleanMeta(item);
    return this.http.post<any>(this.serviceUrl, item);
  }
}
