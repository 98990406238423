import { Directive, HostListener, Input } from '@angular/core';
import { DirtyService } from '@services/dirty/dirty.service';

@Directive({
  selector: '[detectChanges]',
})
export class DetectChangesDirective {
  constructor(private dirtyService: DirtyService) {}

  // tslint:disable-next-line:no-input-rename
  @Input('detectChanges') detectChanges: boolean;

  @HostListener('change', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onChange(event: any): void {
    this.dirtyService.detectChanges();
  }

  @HostListener('keyup', ['$event'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public onKeyup(event: any): void {
    this.dirtyService.detectChanges();
  }
}
