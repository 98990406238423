import { Injectable } from '@angular/core';
import { BaseService } from '@base/base.service';
import { PagedResult } from '@base/lobby-base.component';

export interface Version {
  modelName: string;
  recordId: string;
  date_entered: Date;
  versionData: any;
}

@Injectable()
export class VersionsService extends BaseService<Version> {
  serviceUrl = '/api/versions';

  getByIdAndModelName(modelName, id) {
    return this.http.get<PagedResult<Version>>(`${this.serviceUrl}/${modelName}/${id}`);
  }

  activateVersion(modelName, id, versionId: string) {
    return this.http.post<any>(`${this.serviceUrl}/${modelName}/${id}`, { versionId });
  }

  deleteVersion(modelName, id, versionId: string) {
    return this.http.delete<any>(`${this.serviceUrl}/${modelName}/${id}/${versionId}`);
  }

  deleteVersions(modelName, id) {
    return this.http.delete<any>(`${this.serviceUrl}/${modelName}/${id}`);
  }
}
