 
 <div class="main">
   <div class="bg"></div>
   <div class="text-bg">
   <div class="text">
      Signs
   </div>
</div>

   <div class="learnmore"><a class="btn btn-primary btn-lg" routerLink="learn">Learn more</a></div>
   <div class="manage"><a class="btn btn-success btn-lg" routerLink="dashboard">Manage</a></div>
</div>
 