import { NgModule, ModuleWithProviders } from '@angular/core';
import { DictionaryService } from '@services/dictionary/dictionary.service';
import { NameService } from '@services/name/name.service';
import { ResponsiveService } from '@services/responsive/responsive.service';
import { HistoryService } from '@services/history/history.service';
import { FilterService } from '@services/filter/filter.service';
import { UploadModule } from './common/upload/upload.module';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { DataModule } from './data.module';
import { UsersCache } from '@services/users-cache/users-cache.service';
import { PipesModule } from './pipes/pipes.module';
import { SharedModulesModule } from './shared-modules.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedComponentsModule } from './shared-components.module';

@NgModule({
  imports: [PipesModule, SharedComponentsModule, TranslateModule.forChild(), SharedModulesModule.forRoot(), UploadModule, DataModule.forRoot()],
  providers: [DictionaryService, NameService, ResponsiveService, HistoryService, FilterService, BsDropdownConfig, UsersCache],
  exports: [PipesModule, SharedComponentsModule, SharedModulesModule, UploadModule, DataModule],
})
export class SharedModule {
  constructor(translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');
  }

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
