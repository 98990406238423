import { Injectable } from '@angular/core';
import { BaseService } from '@base/base.service';
import { Observable } from 'rxjs';
import { Exception } from './models/exceptions.model';

@Injectable()
export class ExceptionsService extends BaseService<Exception> {
  serviceUrl = '/api/exceptions';

  decrypt(item: any): Observable<any> {
    item = this.cleanMeta(item);
    return this.http.post<any>(this.serviceUrl, item);
  }
}
