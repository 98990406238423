import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@services/language/language.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-language-bar',
  templateUrl: './language-bar.component.html',
  styleUrls: ['./language-bar.component.scss'],
})
export class LanguageBarComponent implements OnInit {
  languages: any[];

  selectedLanguage: any;

  cssUrl: string;

  cleanCssUrl: any;

  constructor(public translate: TranslateService, public languageService: LanguageService, public sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.languages = this.languageService.languages;
    this.selectedLanguage = this.languageService.getLanguage();
    this.cleanCssUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.languageService.cssUrl);
  }

  selectLanguage() {
    this.languageService.setLanguage(this.selectedLanguage);
    this.cleanCssUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.languageService.cssUrl);
  }

  compareFn(a, b) {
    if (b) {
      return a.lcid === b.lcid;
    }
  }
}
