import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {
  transform(values: any[], objectKeyWithPath = '', order = 'asc'): any {
    if (!values) {
      return values;
    }
    const predicatePathValues: string[] = objectKeyWithPath.split('.');
    const desc: boolean = order === 'desc';
    if (!objectKeyWithPath) {
      return desc
        ? values
            .slice()
            .sort((a, b) => (a > b ? 1 : -1))
            .reverse()
        : values.slice().sort((a, b) => (a > b ? 1 : -1));
    }

    return values.sort((a, b) => {
      let leftSideValue: any = a;
      let rightSideValue: any = b;

      predicatePathValues.forEach((predicate) => {
        leftSideValue = leftSideValue[predicate] || 0;
        rightSideValue = rightSideValue[predicate] || 0;
      });

      return this.handleLogic(leftSideValue, rightSideValue, desc);
    });
  }

  handleLogic(leftSideValue, rightSideValue, desc) {
    let result: any = 0;
    if (leftSideValue < rightSideValue) {
      result = desc ? 1 : -1;
    } else if (rightSideValue < leftSideValue) {
      result = desc ? -1 : 1;
    } else if (!leftSideValue && rightSideValue) {
      result = desc ? 1 : -1;
    } else if (leftSideValue && !rightSideValue) {
      result = desc ? -1 : 1;
    }
    return result;
  }
}
