<ng-template #defaultToolbar let-item="item">
  <ul class="sqr-toolbar btn-group" role="group">
    <li>
      <button class="btn btn-secondary btn-md btn-primary left" (click)="editItemHandler(item)"><em class="icon-edit"></em></button>
    </li>
    <li class="date-label">
      {{ item.date_entered | date }}
    </li>
    <li>
      <button class="btn btn-secondary btn-md btn-danger right" (click)="deleteItemHandler($event, item)"><em class="icon-trash-o"></em></button>
    </li>
  </ul>
</ng-template>

<div class="lobby" *ngIf="mode === 'media'">
  <div *ngIf="!noNew" class="sqr shadow sqr-btn {{ itemClass }}" (click)="newItemHandler({})" (next)="newItemHandler({})"><em class="icon-plus-alt"></em></div>
  <div *ngFor="let item of items | sortBy : 'name'" class="sqr shadow {{ item._meta_active }}" (click)="selectItemHandler(item)">
    <app-lobby-item [item]="item" [model]="model" [width]="itemWidth" [height]="itemHeight"></app-lobby-item>
    <ng-template [ngTemplateOutlet]="toolbar || defaultToolbar" [ngTemplateOutletContext]="{ item: item }"> </ng-template>
  </div>
</div>

<div class="lobby" *ngIf="mode === 'media-finder'">
  <div *ngFor="let item of items | sortBy : 'name'" class="sqr shadow {{ itemClass }} {{ item._meta_active }}">
    <app-lobby-item [item]="item" [model]="model" [width]="itemWidth" [height]="itemHeight"></app-lobby-item>
    <ul class="sqr-toolbar btn-group" role="group">
      <li>
        <button class="btn btn-secondary btn-md btn-primary" (click)="selectItemHandler(item)"><em class="icon-plus-square"></em></button>
      </li>
    </ul>
  </div>
</div>

<div class="lobby" *ngIf="mode === 'object-finder'">
  <div *ngFor="let item of items | sortBy : 'name'" class="sqr shadow {{ itemClass }}">
    <app-lobby-item [item]="item" [model]="model" [width]="itemWidth" [height]="itemHeight"></app-lobby-item>
    <ul class="sqr-toolbar btn-group" role="group">
      <li>
        <button class="btn btn-secondary btn-md btn-primary" (click)="selectItemHandler(item)"><em class="icon-plus-square"></em></button>
      </li>
    </ul>
  </div>
</div>
<div class="lobby" *ngIf="mode === 'object'">
  <div *ngIf="!noNew" class="sqr shadow sqr-btn {{ itemClass }}" (next)="nextTourStep()" (next)="newItemHandler({})" (click)="newItemHandler({})">
    <em class="icon-plus-alt"></em>
  </div>
  <div *ngFor="let item of items | sortBy : 'name'" class="sqr shadow {{ item._meta_active }}" (click)="selectItemHandler(item)">
    <div (click)="editItemHandler(item)" class="fill">
      <app-lobby-item [item]="item" [model]="model" [width]="itemWidth" [height]="itemHeight"></app-lobby-item>
    </div>

    <ul class="sqr-toolbar btn-group" role="group">
      <li>
        <button class="btn btn-secondary btn-md btn-danger right" (click)="deleteItemHandler($event, item)"><em class="icon-trash-o"></em></button>
      </li>
    </ul>
  </div>
</div>

<div class="lobby" *ngIf="mode === 'table'">
  <button *ngIf="!noNew" class="btn btn primary {{ itemClass }}" (next)="nextTourStep()" (next)="newItemHandler({})" (click)="newItemHandler({})">
    <em class="icon-plus-alt"></em>
  </button>
  <table class="table table-striped table-hover" aria-label="data table">
    <thead *ngIf="headerTemplate" class="static-header">
      <tr>
        <ng-template [ngTemplateOutlet]="headerTemplate" [ngTemplateOutletContext]="{ item: items }"> </ng-template>
        <th class="head-small" scope="none"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items | sortBy : 'name'" [ngClass]="{ active: item._meta_active }" (click)="editItemHandler(item)">
        <ng-template [ngTemplateOutlet]="template" [ngTemplateOutletContext]="{ item: item }"> </ng-template>
        <td>
          <div class="btn-group">
            <button class="btn btn-xs btn-primary" (click)="editItemHandler(item)"><em class="icon-edit"></em></button>
            <button class="btn btn-xs btn-danger" (click)="deleteItemHandler($event, item)"><em class="icon-trash-o"></em></button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #lobbyContent>
  <h5>{{ 'TOUR.ADD.TITLE' | translate }}</h5>
  <p>
    {{ 'TOUR.ADD.CONTENT' | translate }}
  </p>
</ng-template>
