import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DirtyService } from '@services/dirty/dirty.service';

@Component({
  selector: 'app-slide-footer',
  templateUrl: './slide-footer.component.html',
  styleUrls: ['./slide-footer.component.scss'],
})
export class SlideFooterComponent {
  constructor(private dirtyService: DirtyService) {
    dirtyService.error.subscribe((error) => {
      this.error = error ? 'error' : '';
    });
  }

  error: string;

  @Input() title: string;

  @Input() className: string;

  @Input() iconClass: string;

  @Input() closeFn: () => void;

  @Output() closed = new EventEmitter();

  closeSlider() {
    this.closed.emit(true);
  }
}
