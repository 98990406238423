import { Component, ViewChild, NgZone, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { UploadService } from './upload.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UploadComponent,
    },
  ],
})
export class UploadComponent implements ControlValueAccessor {
  @ViewChild('file') file;

  @ViewChild('imgIndicator') imgIndicator;

  count = 0;

  public files: Set<File> = new Set();

  loading: boolean;

  uploadComplete = false;

  thumb: string;
  // @Input() item: Record<string, any>;
  // @Input() field: string;

  _value: string;

  @Input() fileType: string;

  constructor(public _ngZone: NgZone, private uploadService: UploadService) {}

  writeValue(filename: string): void {
    this._value = filename;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  disabled: boolean;

  touched: boolean;

  onTouched: () => void;

  onChange: (data) => void;

  addFiles() {
    this.file.nativeElement.click();
  }

  public clearUpload() {
    this._ngZone.run(async () => {
      this.uploadComplete = true;
      this.loading = false;
    });
  }

  public waitUpload(src) {
    this._ngZone.run(async () => {
      if (!this.uploadComplete) {
        this.count++;
        this.loading = true;
        if (this.fileType !== 'font') {
          if (this.imgIndicator.nativeElement && src) {
            this.imgIndicator.nativeElement.src = src;
          } else {
            this.imgIndicator.src = src;
          }
          setTimeout(() => {
            this.waitUpload(src);
          }, 3000);
        }
      } else {
        this.onChange(src);
      }
    });
  }

  public async selected() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key, 2))) {
        this.uploadComplete = false;
        const s3Path = await firstValueFrom(this.uploadService.upload(files[key]));
        this._value = s3Path.url;
        this.onChange(this._value);
      }
    }
  }

  public dropped(event: any) {
    for (const droppedFile of event.files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry: any = droppedFile.fileEntry;
        fileEntry.file(async (file: File) => {
          this.uploadComplete = false;
          const s3Path = await firstValueFrom(this.uploadService.upload(file));
          this.waitUpload(s3Path);
        });
      }
    }
  }
}
