import { Component, OnInit, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { SplitterService } from '@services/splitter/splitter.service';
import { DirtyService } from '@services/dirty/dirty.service';
import moment from 'moment';

@Component({
  selector: 'app-slide-header',
  templateUrl: './slide-header.component.html',
  styleUrls: ['./slide-header.component.scss'],
})
export class SlideHeaderComponent implements OnInit {
  constructor(public dirtyService: DirtyService, public splitterService: SplitterService) {
    dirtyService.dirty.subscribe((state) => {
      this.dirty = state;
    });

    dirtyService.saving.subscribe((state) => {
      this.saving = state;
    });
  }

  @Input() title: string;

  @Input() className: string;

  realDates?: { entered: moment.Moment; changed: moment.Moment; published: moment.Moment };

  canPublish = false;

  itemAge: moment.Moment;

  get dates() {
    return this.realDates;
  }

  @Input() set dates(value: { entered: any; changed: any; published: any }) {
    if (value) {
      const entered = value.entered ? moment(value.entered) : null;
      const changed = value.changed ? moment(value.changed) : null;
      const published = value.published ? moment(value.published) : null;

      this.realDates = { entered, changed, published };

      if (!this.realDates.published) {
        this.canPublish = true;
        this.itemAge = this.realDates.published;
      } else if (this.realDates.published.diff(this.realDates.changed) < 0) {
        this.canPublish = true;
        this.itemAge = this.realDates.changed;
      } else {
        this.itemAge = this.realDates.published;
        this.canPublish = false;
      }
    } else {
      this.canPublish = true;
    }
  }

  @Input() mode: string;

  @Input() width = '250px';

  @Input() enableSave: boolean;

  @Input() enablePublish: boolean;

  @Input() iconClass: string;

  @Input() closeFn: boolean;

  @Input() toolbar: TemplateRef<any>;

  @Output() closed = new EventEmitter<any>();

  @Output() save = new EventEmitter<any>();

  @Output() publish = new EventEmitter<any>();

  dirty: boolean;

  saving: boolean;

  class = 'inline-topper';

  ngOnInit() {
    this.class = 'inline-topper';
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  saveClick(event) {
    this.canPublish = true;
    this.dirtyService.setSaving();
    this.save.emit();
  }

  publishClick(event) {
    this.canPublish = false;
    this.dirtyService.setSaving();
    this.publish.emit();
  }

  closeSlider() {
    this.closed.emit(true);
  }
}
