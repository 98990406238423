import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-date-line',
  templateUrl: './date-line.component.html',
  styleUrls: ['./date-line.component.scss'],
})
export class DateLineComponent {
  @Input('item') item: any;
}
