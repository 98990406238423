import { Injectable, EventEmitter } from '@angular/core';

export interface SplitterState {
  open: boolean;
  name: string;
}
@Injectable({
  providedIn: 'root',
})
export class SplitterService {
  SAVE_STATE = 'SAVED';

  onOpenSplitter: EventEmitter<SplitterState> = new EventEmitter<SplitterState>();

  openSplitter(name = 'main') {
    this.onOpenSplitter.emit({ name, open: true });
  }

  closeSplitter(name = 'main') {
    this.onOpenSplitter.emit({ name, open: false });
  }
}
