import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { UsersService } from '../../admin/users/users.service';

export const enum CacheKeys {
  users = 'users',
}

@Injectable({
  providedIn: 'root',
})
export class UsersCache {
  constructor(private usersService: UsersService) {}

  init() {
    this.isBusy = true;
    Promise.all([this.updateCache(CacheKeys.users)]).then(() => {
      this.isBusy = false;
      this.onReady.emit();
    });
  }

  isBusy = false;

  public onReady: EventEmitter<void> = new EventEmitter<void>();

  public onUpdate: EventEmitter<CacheKeys> = new EventEmitter<CacheKeys>();

  async getCollection(cacheKey: CacheKeys) {
    await this.verifyCollection(cacheKey);
    return this.dictionaries[cacheKey];
  }

  async getArray(cacheKey: CacheKeys) {
    await this.verifyCollection(cacheKey);
    return JSON.parse(JSON.stringify(this._collections[cacheKey]));
  }

  async fromCollection(cacheKey: CacheKeys, id: string) {
    await this.verifyCollection(cacheKey);
    if (!this.dictionaries[cacheKey][id]) {
      console.error('object not found', cacheKey, id);
    }
    return this.dictionaries[cacheKey][id];
  }

  async verifyCollection(cacheKey: CacheKeys) {
    if (!this.dictionaries[cacheKey]) {
      await this.updateCache(cacheKey);
    }
  }

  dictionaries = {
    [CacheKeys.users]: null,
  };

  _collections = {
    [CacheKeys.users]: null,
  };

  cacheServices = {
    [CacheKeys.users]: this.usersService,
  };

  async updateCache(cacheKey: CacheKeys): Promise<void> {
    this.dictionaries[cacheKey] = {};
    const data = (await firstValueFrom(this.cacheServices[cacheKey].query())).rows;
    this.dictionaries[cacheKey] = this.toDictionary(data);
    this._collections[cacheKey] = data;
    this.onUpdate.emit(cacheKey);
  }

  removeSingleEntry(cacheKey: CacheKeys, id) {
    delete this.dictionaries[cacheKey][id];
    const organ = this._collections[cacheKey].filter((single) => single._id === id);
    const indexPosition = this._collections[cacheKey].indexOf(organ[0]);
    if (indexPosition > -1) {
      this._collections[cacheKey].splice(indexPosition, 1);
    }
    this.onUpdate.emit();
  }

  updateSingleEntry(cacheKey: CacheKeys, id, item) {
    const newRef = JSON.parse(JSON.stringify(item));
    this.dictionaries[cacheKey][id] = newRef;
    const organ = this._collections[cacheKey].filter((single) => single._id === id);
    const indexPosition = this._collections[cacheKey].indexOf(organ[0]);
    if (indexPosition > -1) {
      this._collections[cacheKey][indexPosition] = newRef;
    } else {
      this._collections[cacheKey].push(newRef);
    }
    this.sendMessage(newRef);
    this.onUpdate.emit();
  }

  toDictionary(jArray: any[]) {
    const jObject: Record<string, any> = {};
    jArray.forEach((question) => {
      jObject[question._id] = question;
    });
    return jObject;
  }

  sendMessage(msg: string) {
    console.info(msg);
  }
}
