import { Injectable } from '@angular/core';
import { ExceptionsService } from '@products-main/exceptions/exceptions.service';
import { firstValueFrom } from 'rxjs';
import { TreatmentsService } from '../../treatments/treatments.service';
import { ProductsService } from '../products.service';

export const enum ProductCacheKeys {
  products = 'products',
  treatments = 'treatments',
  exceptions = 'exceptions',
}

@Injectable()
export class ProductsCache {
  constructor(private productsService: ProductsService, private treatmentsService: TreatmentsService, private exceptionsService: ExceptionsService) {}

  async getCollection(cacheKey: ProductCacheKeys) {
    await this.verifyCollection(cacheKey);

    return this.collections[cacheKey];
  }

  async getArray(cacheKey: ProductCacheKeys) {
    await this.verifyCollection(cacheKey);
    return this._collections[cacheKey];
  }

  async fromCollection(cacheKey: ProductCacheKeys, id: string) {
    await this.verifyCollection(cacheKey);
    return this.collections[cacheKey][id];
  }

  async verifyCollection(cacheKey: ProductCacheKeys) {
    if (!this.collections[cacheKey]) {
      await this.updateCache(cacheKey);
    }
  }

  collections = {
    [ProductCacheKeys.products]: null,
    [ProductCacheKeys.treatments]: null,
    [ProductCacheKeys.exceptions]: null,
  };

  _collections = {
    [ProductCacheKeys.products]: null,
    [ProductCacheKeys.treatments]: null,
    [ProductCacheKeys.exceptions]: null,
  };

  cacheServices = {
    [ProductCacheKeys.products]: this.productsService,
    [ProductCacheKeys.treatments]: this.treatmentsService,
    [ProductCacheKeys.exceptions]: this.exceptionsService,
  };

  async updateCache(cacheKey: ProductCacheKeys): Promise<void> {
    const data = await firstValueFrom(this.cacheServices[cacheKey].query());
    this.collections[cacheKey] = this.toDictionary(data.rows);
    this._collections[cacheKey] = data.rows;
  }

  toDictionary(jArray: any[]) {
    const jObject: Record<string, any> = {};
    jArray.forEach((question) => {
      jObject[question._id] = question;
    });
    return jObject;
  }
}
