import { Component, EventEmitter, Input, Output, ViewChild, TemplateRef, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-lobby',
  templateUrl: './lobby.component.html',
  styleUrls: ['./lobby.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LobbyComponent implements OnChanges {
  @ViewChild('imageTemplate') imageTemplate: TemplateRef<any>;

  @ViewChild('playlistTemplate') playlistTemplate: TemplateRef<any>;

  @Input() toolbar: TemplateRef<any>;

  @Input() template: TemplateRef<any>;

  @Input() headerTemplate: TemplateRef<any>;

  @Input() items: any[];

  @Input() model: string;

  @Input() noNew = false;

  @Input() mode: string;

  @Input() iconClass: string;

  @Input() itemClass = '';

  @Input() itemWidth: number;

  @Input() itemHeight: number;

  @Output() deleteItem = new EventEmitter<any>();

  @Output() editItem = new EventEmitter<any>();

  @Output() newItem = new EventEmitter<any>();

  @Output() selectItem = new EventEmitter<any>();

  deleteItemHandler(event, item) {
    event.stopPropagation();
    this.deleteItem.emit(item);
  }

  editItemHandler(item) {
    this.clearSelection();
    item._meta_active = true;
    this.editItem.emit(item);
  }

  newItemHandler(item) {
    this.newItem.emit(item);
  }

  clearSelection() {
    this.items.forEach((it) => {
      delete it._meta_active;
    });
  }

  selectItemHandler(item) {
    this.clearSelection();
    item._meta_active = '_meta_active';
    this.selectItem.emit(item);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items) {
      // && !changes['items'].isFirstChange()

      if (this.items) {
        this.items.forEach((item) => {
          if (this.template) {
            item.template = this.template;
          }
        });
      }
    }
  }

  nextTourStep() {
    return new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
  }
}
