import { Component, OnInit, Input, ViewChild, TemplateRef, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-lobby-item',
  templateUrl: './lobby.item.component.html',
  styleUrls: ['./lobby.item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LobbyItemComponent implements OnInit, OnChanges {
  @ViewChild('playlistTemplate') playlistTemplate: TemplateRef<any>;

  @ViewChild('imageTemplate') imageTemplate: TemplateRef<any>;

  @ViewChild('urlTemplate') urlTemplate: TemplateRef<any>;

  @ViewChild('videoTemplate') videoTemplate: TemplateRef<any>;

  @Input() template: TemplateRef<any>;

  @Input() item: any;

  @Input() model: string;

  @Input() iconClass: string;

  @Input() itemClass: string;

  @Input() width: number;

  @Input() height: number;

  ngOnInit() {
    if (!this.width) {
      this.width = 230;
      this.height = 150;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ngOnChanges(changes: SimpleChanges): void {
    if (this.item) {
      if (!this.item.template) {
        if (this.item.type === 'image' || this.item.MediaType === 'image') {
          this.template = this.imageTemplate;
        }

        if (this.item.MediaType === 'video') {
          this.template = this.videoTemplate;
        }

        if (this.item.MediaType === 'url') {
          this.template = this.urlTemplate;
        }
      } else {
        this.template = this.item.template;
      }
    }
  }
}
