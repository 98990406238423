import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { UploadComponent } from './upload.component';
import { UploadService } from './upload.service';

@NgModule({
  imports: [CommonModule],
  declarations: [UploadComponent, MediaPreviewComponent],
  providers: [UploadService],
  exports: [UploadComponent, MediaPreviewComponent],
})
export class UploadModule {
  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: UploadModule,
      providers: [UploadService],
    };
  }
}
