import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class RemoteCacheService<T = any> {
  constructor(private http: HttpClient) {}

  serviceUrl = 'api/delete-cache';

  clear(withProd): Observable<any> {
    return this.http.patch<any>(`${this.serviceUrl}${withProd ? '?prod=1' : ''}`, {});
  }

  // async delete(withProd: boolean = false): Promise<any> {
  //   const domain = window.location.host.replace('admin', 'pro');
  //   const finalUrl = `//${domain}${this.serviceUrl} ${withProd ? '?prod=1' : ''}`;
  //   const cacheScript = document.createElement('script');
  //   cacheScript.setAttribute('src', finalUrl);
  //   document.head.appendChild(cacheScript);
  //   return 'ok';
  //   // return this.http.get<any[]>(`${environment.proUrl}/${this.serviceUrl}`));
  // }
}
