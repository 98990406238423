<ng-template [ngTemplateOutlet]="template || defaultTemplate" [ngTemplateOutletContext]="{ item: item }"> </ng-template>

<ng-template #defaultTemplate let-item="item">
  <div class="m10">
    <span class="fancy-thumb-label">{{ item.Name }}</span>
  </div>
</ng-template>

<ng-template #imageTemplate let-item="item">
  <img class="fancy-thumb" [src]="item.resource" [width]="width" [height]="height" alt="{{ item.Name }}" />
  <span class="fancy-thumb-label"> <em class="icon-image"></em> {{ item.Name }}</span>
  <app-publish-indicator [item]="item"></app-publish-indicator>
</ng-template>

<ng-template #videoTemplate let-item="item">
  <span class="fancy-thumb-label"> <em class="icon-play"></em> {{ item.Name }}</span>
  <app-publish-indicator [item]="item"></app-publish-indicator>
</ng-template>

<ng-template #urlTemplate let-item="item">
  <span class="fancy-thumb-label"> <em class="icon-chain"></em> {{ item.Name }}</span>
  <app-publish-indicator [item]="item"></app-publish-indicator>
</ng-template>

<ng-template #playlistTemplate let-item="item">
  <div class="m10">
    <span class="label">{{ item.Name }}</span>
    <span class="label">({{ item.list.length }})</span>
    <span class="label">{{ item.Date | date }}</span>
  </div>
  <app-publish-indicator [item]="item"></app-publish-indicator>
</ng-template>

 