import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LobbyItemComponent } from '@common/lobby.item/lobby.item.component';
import { LobbyComponent } from '@common/lobby/lobby.component';
import { PublishIndicatorComponent } from '@common/publish-indicator/publish-indicator.component';
import { SlideFooterComponent } from '@common/slide-footer/slide-footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { ItemVersionsComponent } from './item-versions/item-versions.component';
import { SlideHeaderComponent } from '@common/slide-header/slide-header.component';
import { SortIndicatorComponent } from '@common/sort-indicator/sort-indicator.component';
import { LobbyFilterComponent } from '@common/lobby-filter/lobby-filter.component';
import { DateLineComponent } from '@common/date-line/date-line.component';
import { LanguageBarComponent } from '@common/language-bar/language-bar.component';
import { PipesModule } from '@pipes/pipes.module';
import { LoaderComponent } from '@common/loader/loader.component';
import { MomentModule } from 'ngx-moment';

const declarations = [
  ItemVersionsComponent,
  SortIndicatorComponent,
  PublishIndicatorComponent,
  LobbyFilterComponent,
  LobbyComponent,
  LobbyItemComponent,
  SlideHeaderComponent,
  SlideFooterComponent,
  DateLineComponent,
  LanguageBarComponent,
  LoaderComponent,
];

@NgModule({
  imports: [CommonModule, MomentModule, TranslateModule.forChild(), PipesModule],
  declarations,
  exports: [...declarations],
})
export class SharedComponentsModule {}
